import { useEffect, useContext, useState, useCallback } from 'react';
import PropertiesList from 'pages/ContentPage/Properties/PropertiesList.js';
import { SiteSettingsContext, CurrencyContext } from 'contexts/SiteSettingsProvider';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import SearchControlPanel from 'components/SearchResult/SearchControlPanel/SearchControlPanel';
import useWindowSize from "utils/useWindowSize";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import SearchResultMap from 'components/SearchResultMap/SearchResultMap';
import SearchForm from './SearchForm/SearchForm';
import SearchFormMobile from './SearchFormMobile/SearchFormMobile';
import { SortContext } from 'contexts/SiteSettingsProvider';
import moment from 'moment';

const PropertyAutoSelectorWidget = ({ data, searchFormSettings, googleMapAPIKey }) => {
    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [infants, setInfants] = useState(0);
    const [locations, setLocations] = useState(
        data.LocationsIds ? data.LocationsIds.join(',') : ''
    );
    const [checkIn, setCheckIn] = useState(null);
    const [checkOut, setCheckOut] = useState(null);
    const [changedDates, setChangedDates] = useState(null);
    const [attributes, setAttributes] = useState(data.PropertyAttributesIds ? data.PropertyAttributesIds.join(',') : '');   
 
    const [priceFilterType, setPriceFilterType] = useState(null);
    const [nightRange, setNightRange] = useState(null);
    const [weekRange, setWeekRange] = useState(null);
    const [flexibleNights, setFlexibleNights] = useState(null);
    const [priceRange, setPriceRange] = useState(null);
 
    const [sortParam, setSortParam] = useState(searchFormSettings.DefaultSortDirection);
    const [orderParam, setOrderParam] = useState(searchFormSettings.DefaultSortOrder);
    const [isLoading, setIsLoading] = useState(false);
    const [currency, setCurrency] = useContext(CurrencyContext);
    const { width } = useWindowSize();
    const [mapIsShown, setMapVisability] = useState(true);
    const [displayMode, setDisplayMode] = useState("map");
    const [gridClass, setGridClass] = useState("tw-grid-cols-2");
    const [widgetPropertiesListGridClass, setPropertiesListGridClass] = useState("tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-5 lg:tw-grid-cols-5 tw-gap-4 tw-gap-y-6");
    const [propertyList, setPropertyList] = useState(null);
    const [total, setTotal] = useState(data.Total);
    const [showMobileForm, setShowMobileForm] = useState(false);
    const [linkParams, setLinkParams] = useState('');

    const [bgImage, setBgImage] = useState(null);
 
    const { setSort, setOrder } = useContext(SortContext);

    useEffect(() => {
   
        setSort(searchFormSettings.DefaultSortDirection);
        setOrder(searchFormSettings.DefaultSortOrder);
    }, [sortParam, orderParam]);



    useEffect(() => {
        if (data) {
            setPropertyList(data.PropertiesList);
            setAttributes(data.PropertyAttributesIds);
            if (data?.Image?.Url) {
                setBgImage(data.Image.Url);
            }
        }
    }, [data]);

    useEffect(() => {
        if (width < window.lg) {
            setMapVisability(false);
        } else {
            setMapVisability(true);
        }
    }, [width]);
 

    const changeSorting = useCallback((sortDirection = orderParam, sort = sortParam, page=1) => {

        setIsLoading(true);
        setSortParam(sort);
        setOrderParam(sortDirection);
        
        const constructSearchUrl = () => {
            return `${process.env.REACT_APP_BASE_URL}apis/searchproperties?clearcache=true&Location=${locations}&Adults=${adults}&Children=${children}&Infants=${infants}&CheckIn=${checkIn}&CheckOut=${checkOut}&Attributes=${attributes}&PriceFilterType=${priceFilterType}&NightlyPriceRange=${priceRange}&WeeklyPriceRange=${priceRange}&Currency=${currency}&FlexibleNights=${flexibleNights}&Sort=${sortDirection || orderParam}&SortDirection=${sort || sortParam}&Page=${page || 1}&PageSize=${20}`; 
        };

 
        fetch(constructSearchUrl())
            .then(response => response.json())
            .then(data => {
                setPropertyList(data.Items);
                setTotal(data.Total);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });

 

        let paramsArray = [
            adults && `Adults=${adults}`,
            children && `Children=${children}`,
            infants && `Infants=${infants}`,
            checkIn && `CheckIn=${checkIn}`,
            checkOut && `CheckOut=${checkOut}`
        ].filter(Boolean);

        let linkParams = paramsArray.length ? `?${paramsArray.join('&')}` : '';

        setLinkParams(linkParams);
        
    }, [adults, children, infants, locations, checkIn, checkOut, attributes, priceFilterType, priceRange, currency, sortParam, orderParam]);

    useEffect(() => {
        if (mapIsShown === false) {
            setDisplayMode('fullWidth');
            setGridClass("tw-grid-cols-1");
            setPropertiesListGridClass("tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-5 lg:tw-grid-cols-5 tw-gap-4 tw-gap-y-6");
        }
        if (mapIsShown === true) {
            setDisplayMode('map');
            setGridClass("tw-grid-cols-5");
            setPropertiesListGridClass("tw-grid tw-col-span-3 tw-grid-cols-1 tw-gap-4");
        }
    }, [mapIsShown, displayMode]);

    const upMapState = useCallback((val) => {
        setMapVisability(!val);
    }, []);

    const updateResults = useCallback((data) => {
     
        changeSorting(null, null, data.Page);
    }, [changeSorting]);

    const changeAdults = useCallback((val) => {
        setAdults(val);
    }, []);

    const changeChildren = useCallback((val) => {
        setChildren(val);
    }, []);

    const changeInfants = useCallback((val) => {
        setInfants(val);
    }, []);

    useEffect(() => {
        if (changedDates && !changedDates[0] && !changedDates[1]) {   
            setChangedDates(changedDates);
        }
    }, [checkIn, checkOut, changedDates]);

    const transferDates = useCallback((dates) => {
        
        if (dates && dates[0] !== null && dates[1] !== null) {
            console.log('transferDates', dates);
            const formattedDates = dates.map(date => moment(date).format('DD/MM/YYYY'));
            setCheckIn(formattedDates[0]);
            setCheckOut(formattedDates[1]);

            if (dates && dates[0] && dates[1]) {
                setChangedDates(dates);
            }
            
        }
    }, []);

    const transferFlexibility = useCallback((val) => {
        setFlexibleNights(val);
    }, []);

    const transNightRange = useCallback((val) => {
        setNightRange(val);
    }, []);

    const transWeekRange = useCallback((val) => {
        setWeekRange(val);
    }, []);

    const transferAttrsOnLoad = useCallback((val) => {

    }, []);

    const transferPriceMode = useCallback((val) => {
        setPriceFilterType(val);
    }, []);

    const transferAttrsOnChange = useCallback((val) => {
 
        setAttributes(val);
    }, []);

    const transferLocation = useCallback((val) => { 
        
         setLocations(val);
    }, []);

 
    const startSearch = useCallback((...args) => {
        changeSorting();
       
    }, [changeSorting]);
 
 
    return (
        <WidgetWrapper data={data}>
 
            {bgImage && width > window.lg ? (
                <div className='tw-ml-[-1.5rem] tw-mr-[-1.5rem] tw-mb-[1.5rem]' style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', minHeight: '600px' }}>
                    {data.Title && <h1 className='tw-text-center tw-text-white tw-mb-0 lg:!tw-text-[2.5rem] tw-mb-5'>{data.Title}</h1>}
                    {data.SubTitle && <h2 className='tw-text-center tw-text-white tw-mb-0'>{data.SubTitle}</h2>}
                    {data.Description && <div dangerouslySetInnerHTML={{ __html: data.Description }} />}
                    {width > window.lg &&
                        <div className='tw-p-6 tw-py-2 !tw-pb-0 tw-bg-white'>
                            <SearchForm
                                changeAdults={changeAdults}
                                changeChildren={changeChildren}
                                changeInfants={changeInfants}
                                transferDates={transferDates}
                                transferFlexibility={transferFlexibility}
                                priceRangeSliderSettings={searchFormSettings.PriceRangeSliderSettings}
                                attributes={searchFormSettings.AttibutesSettings}
                                transNightRange={transNightRange}
                                transWeekRange={transWeekRange}
                                transferAttrsOnLoad={transferAttrsOnLoad}
                                transferPriceMode={transferPriceMode}
                                transferAttrsOnChange={transferAttrsOnChange}
                                isFilters={true}
                                searchFormSettings={searchFormSettings}
                                transferLocation={transferLocation}
                                startSearch={startSearch}
                                isLoading={isLoading}
                                locationsIds={data.LocationsIds}
                                propertyAttributesIds={attributes}
                                limitLocations={false}
                            />
                        </div>
                    }
                </div>
            ) : (
                <>
                    {data.Title && <h2>{data.Title}</h2>}
                    {data.SubTitle && <h3>{data.SubTitle}</h3>}
                    {data.Description && <div dangerouslySetInnerHTML={{ __html: data.Description }} />}
                    {width > window.lg &&
                        <SearchForm
                            changeAdults={changeAdults}
                            changeChildren={changeChildren}
                            changeInfants={changeInfants}
                            transferDates={transferDates}
                            transferFlexibility={transferFlexibility}
                            priceRangeSliderSettings={searchFormSettings.PriceRangeSliderSettings}
                            attributes={searchFormSettings.AttibutesSettings}
                            transNightRange={transNightRange}
                            transWeekRange={transWeekRange}
                            transferAttrsOnLoad={transferAttrsOnLoad}
                            transferPriceMode={transferPriceMode}
                            transferAttrsOnChange={transferAttrsOnChange}
                            isFilters={true}
                            searchFormSettings={searchFormSettings}
                            transferLocation={transferLocation}
                            startSearch={startSearch}
                            isLoading={isLoading}
                            locationsIds={data.LocationsIds}
                            propertyAttributesIds={attributes}
                            limitLocations={false}
                        />
                    }
                </>
            )}

            {width < window.lg &&
                <SearchFormMobile
                    transferAdults={changeAdults}
                    transferChildren={changeChildren}
                    transferInfants={changeInfants}
                    transferDates={transferDates}
                    transferFlexibility={transferFlexibility}
                    priceRangeSliderSettings={searchFormSettings.PriceRangeSliderSettings}
                    attributes={searchFormSettings.AttibutesSettings}
                    searchFormSettings={searchFormSettings}
                    transNightRange={transNightRange}
                    transWeekRange={transWeekRange}
                    transferAttrsOnLoad={transferAttrsOnLoad}
                    transferPriceMode={transferPriceMode}
                    transferAttrsOnChange={transferAttrsOnChange}
                    isFilters={true}
                    propertyAttributesIds={attributes}
                    transferLocation={transferLocation}
                    startMobileSearch={startSearch}
                    limitLocations={false}
                    locationsIds={data.LocationsIds}
                    changedNumberOfAdults={adults}
                    changedNumberOfChildren={children}
                    changedNumberOfInfants={infants}
                    changedLocation={locations}
                    changedDates={changedDates}
                />
            }

            <SearchControlPanel upMapState={upMapState} updateSorting={changeSorting} />

            <div className={`tw-grid ${gridClass} tw-gap-3 1`}>
                {(propertyList && propertyList.length > 0) ?
                    <PropertiesList
                        propertyList={propertyList}
                        widgetGrid={widgetPropertiesListGridClass}
                        total={total}
                        sendParams={updateResults}
                        isLoading={isLoading}
                        linkParams={linkParams} 
                    />
                    : null
                }

                {!isLoading && (!propertyList || propertyList.length === 0) &&
                    <div className="tw-col-span-1 tw-text-3xl">No results found</div>
                }

                {(mapIsShown && width > window.lg && propertyList && propertyList.length > 0) &&
                    <div className="lg:tw-columns-1 tw-col-span-2">
                        <div className="tw-sticky tw-top-0">
                            <div>
                                <Wrapper apiKey={googleMapAPIKey}>
                                    <SearchResultMap mapData={propertyList} />
                                </Wrapper>
                            </div>
                        </div>
                    </div>
                }

                {(mapIsShown && width < window.lg && propertyList && propertyList.length > 0) &&
                    <div className="tw-fixed tw-w-full tw-h-full tw-z-[9] tw-top-0 tw-left-0">
                        <div className="map">
                            <div>
                                <Wrapper apiKey={googleMapAPIKey}>
                                    <SearchResultMap mapData={propertyList} />
                                </Wrapper>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </WidgetWrapper>
    );
};

export default PropertyAutoSelectorWidget;
